/* MobileTimeline.css */
.mobile-timeline-container { 
  background-color: #464545;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px; /* Fixed width for consistency */
  height: 65px; /* Increased height */
  margin: 0 5px;
}

.mobile-arrow {
  width: 50px;
  height: 40px;
  cursor: pointer;
  padding: 0px; /* Remove padding */
  margin: -10px; /* Adjusted margin to ensure proper spacing with increased height */
  background-color: transparent; /* Make background transparent */
}

.mobile-timeline-text {
  flex: 1;
  text-align: center;
  color: white;
  font-size: 17px;
  font-weight: bold;
  white-space: normal;
  line-height: 1.2;
  margin-left: 15px;
  margin-right:15px;
}

.mobile-timeline-today,
.mobile-timeline-day {
  display: block;
  word-break: break-word;
}
