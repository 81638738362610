/* src/styles/Summary.css */
#summary {
  padding: 20px;
  background-color: #2f2e2e;
  border-radius: 20px;
  box-shadow: inset 0 0 0 5px #4f4d4d;
  margin: 10px auto;
  max-width: 800px;
  width: calc(100% - 40px);
  position: relative;
}

.summary-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.summary-numbers-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.summary-et-numbers {
  font-weight: bold;
  font-size: 2rem;
  color: #fff;
}

.summary-et-titles {
  font-size: 1rem;
  color: #fff;
}

.sponsored-event {
  display: flex;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
  font-weight: bold !important;
}

.left-logo {
  width: 50px;
  height: auto;
  margin-right: 10px;
}

.custom-divider {
  background-color: #fff;
  width: 100%;
  height: 2px;
}

.sponsored-event:hover {
  opacity: 0.8;
}
