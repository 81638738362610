/* Header.css */
header {
  width: 100%; /* Ensure the header takes the full width of the screen */
  position: relative; /* Keep relative for stacking context */
}

.background-image {
  position: relative;
  width: 100%;
  height: 255px; /* Background image height */
/*  background-image: url('../assets/ubc_image.png'); */ /* Original Background*/ 
  background-image: url('../assets/ubc_image.png'); /* Background image for halloween*/ 
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.logo-container {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10; /* Ensure the logo is above the background image */

}

.header-logo {
  display: block;
  max-height: 120px; /* Adjust the size to make the logo bigger */
  width: auto; /* Maintain aspect ratio */
}

.subscribe-banner {
  position: absolute;
  top: 0;
  width: 100%;
  background-color: #4B4A4A;
  color: white;
  text-align: center;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11; /* Ensure the banner is above the background image */
  height: 36px;
}

.subscribe-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  cursor: pointer; /* Add this line */
}

.subscribe-link {
  color: white;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer; /* Add this line */
}

.subscribe-link .subscribe-text {
  font-weight: bold;
  text-decoration: underline;
}

.subscribe-link:hover .subscribe-text {
  text-decoration: underline;
}

.add-event-button {
  position: absolute !important;
  top: 10px !important;
  right: 10px !important;
  z-index: 12 !important; /* Ensure the button is above other elements */
  padding: 5px 10px; /* Reduce the padding to make the button smaller */
}

.add-event-text {
  display: inline; /* Default display */
}

.add-event-icon {
  display: none; /* Hide by default */
}

.menu-button-container {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 12; /* Ensure the button is above other elements */
}

