/* AddEvent.css */

.add-event-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #2F2E2E;
  color: white;
  border-radius: 10px;
  max-width: 800px;
  margin: 0 auto;
}

.add-event-container h1 {
  color: white !important; /* Force the header color to be white */
}

.add-event-form {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.map-container,
.form-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.upload-section,
.file-details {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 0px;

}

.upload-section label {
  margin-bottom: 10px; /* Add spacing between label and button */
}

.map-container {
  height: 500px !important; /* Reduced height */
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: #3A3A3A;
  border-radius: 10px;
  overflow: hidden;
  bottom: 0px !important;
  margin-bottom: 0px !important;
}

.form-buttons {
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
