.subscription-form-container {
  max-width: 500px;
  margin: 50px auto;
  padding: 20px;
  background-color: #393838;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.subscription-form-container form {
  display: flex;
  flex-direction: column;
}

.back-button {
  margin-bottom: 10px;
  background-color: #4B4A4A;
  color: white;
}

.subscription-form-container .MuiFormControlLabel-root {
  color: white; /* Label color */
}

.subscription-form-container .MuiFormControlLabel-root .MuiCheckbox-root {
  color: #4F4D4D; /* Checkbox color when unchecked */
}

.subscription-form-container .MuiCheckbox-root .MuiSvgIcon-root {
  fill: lightgrey; /* Checkbox icon color */
}

.subscription-form-container .MuiCheckbox-root .MuiSvgIcon-root path {
  stroke: #4F4D4D; /* Checkbox border color */
  stroke-width: 1; /* Border thickness */
}
