/* MobileHeader.css */
.mobile-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  background-color: #2F2E2E;
  width: 100vw;
  box-sizing: border-box;
  text-align: center;
  margin-bottom: 20px; /* Adjust as necessary to position below the logo */
}

@media (max-width: 768px) {
  .background-image {
    height: 150px;
    background-image: none;
  }

  .header-logo {
    max-height: 50px;
    margin: 0 auto;
  }

  .subscribe-banner {
    display: flex;
    flex-direction: row;
    padding: 10px 5px;
    min-width: 100vw;
    width: 300px;
    justify-content: center;
    align-items: center;
    visibility: hidden;
  }

  .subscribe-icon {
    width: 20px;
    height: 20px;
  }

  .subscribe-link {
    color: white;
    text-decoration: none;
    font-size: 16px;
    display: flex;
    align-items: center;
  }

  .subscribe-text {
    font-weight: bold;
    text-decoration: underline;
  }

  .subscribe-link:hover .subscribe-text {
    text-decoration: underline;
  }

  .add-event-text {
    display: none;
  }

  .add-event-button {
    display: none;
    visibility: hidden;
  }

  .add-event-text {
    display: none;
  }

  .add-event-icon {
    display: none;
  }
}
