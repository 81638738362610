/* Container for the filter component */
.filter-container {
    display: flex;
    flex-direction: row;
    align-items: center; /* Vertically center items */
    margin-bottom: 20px;
    padding: 0;
    width: 100%;
    box-sizing: border-box;
    gap: 10px; /* Add space between buttons */
}

/* Styling for the filter bubble */
.filter-bubble {
    padding: 15px 30px; /* Consistent padding */
    background-color: #343434;
    border-radius: 15px;
    border: 4px solid #464444;
    font-size: 20px;
    color: #FFFFFF;
    font-weight: bold;
    cursor: pointer;
    position: relative;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center; /* Vertically center content */
    justify-content: center; /* Center content horizontally */
    white-space: nowrap;

}

/* Styling for the filter text */
.filter-text {
    display: flex;
    align-items: center;
    margin: 0; /* Remove any default margins */
}

/* Styling for the dropdown icon */
.dropdown-icon {
    font-size: 16px;
    margin-left: 10px;
}

/* Hover effect for filter bubbles */
.filter-bubble:hover {
    background-color: #3b3b3b;
}

/* Styling for the dropdown menu */
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #464545;
    border-radius: 10px;
    margin-top: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: auto;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(2, minmax(150px, 1fr));
    gap: 10px 20px;
}

/* Styling for each dropdown item */
.dropdown-item {
    padding: 5px 20px;
    color: #FFFFFF;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Hover effect for dropdown items */
.dropdown-item:hover {
    background-color: #3b3b3b;
}

/* Style for selected items in the dropdown */
.dropdown-item.selected {
    font-weight: bold;
}

/* Styling for the checkmark indicating selection */
.checkmark {
    font-size: 14px;
    margin-left: 10px;
    color: #FFFFFF;
}

/* Container for selected options */
.selected-options {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    overflow-x: auto;
    margin-left: 10px;
}

/* Styling for selected option bubbles */
.selected-option {
    background-color: #464545;
    border-radius: 10px;
    border: 1px solid #ccc;
    padding: 5px 10px;
    margin-right: 5px;
    font-size: 14px;
    color: #FFFFFF;
    white-space: nowrap;
    display: flex;
    align-items: center;
    box-sizing: border-box;
}

/* Styling for the remove ("x") button inside selected options */
.selected-option .remove-option {
    margin-left: 10px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    color: #FFFFFF;
}

/* Styling for the "View Halloween Events" button */
.halloween-button {
    background-color: #FF5733;
    color: white;
    font-size: 20px;
    padding: 15px 30px; /* Consistent padding */
    border: none;
    border-radius: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: bold;
    display: flex; /* Use flex to align content */
    align-items: center; /* Vertically center content */
    justify-content: center; /* Center content horizontally */
    margin-top:0px;
    padding-top: 18px;
    padding-bottom: 18px;
}

.halloween-button.active {
    background-color: #d63504;
}

.halloween-button:hover {
    background-color: #E64A19;
}
