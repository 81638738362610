/* App.css */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #2F2E2E;
  color: #fff;
  overflow-x: hidden; /* Prevent horizontal overflow */
  min-width: 411px; /* Set the minimum width for the viewport */
  touch-action: pan-y; /* Lock horizontal movement, only allow vertical */
}

.App {
  min-width: 411px; /* Prevent App container from shrinking below 411px */
  width: 100vw;
  max-width: 100vw; /* Prevent elements within App from causing overflow */
}

.main-content {
  display: flex;
  padding: 0 20px;  /* Adjust the padding value to increase/decrease spacing */
}

.background-image {
  position: relative;
  width: 100vw; /* Ensure it takes the full width of the viewport */
  height: 255px;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.header-logo {
  max-height: 85px;
  z-index: 10;
}

.left-content {
  width: 66.67%;
  padding: 20px;
}

.right-content {
  width: 33.33%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 10px; /* Add gap between DatePickerComponent, Summary, and MapComponent */
}

.mobile-button-container {
  display: flex;
  justify-content: center; /* Center the buttons horizontally */
  width: 100%;
  max-width: 600px; /* Optional: limit the max width to prevent excessive stretching */
  gap: 10px; /* Add space between the buttons */
}

.mobile-button {
  background-color: #464545;
  border-radius: 20px;
  padding: 8px; /* Reduced padding to make the button smaller */
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1; /* Allow buttons to grow equally */
  max-width: 80px; /* Limit the max width for buttons */
}

/* Center and adjust the date display */
h2 {
  text-align: center; /* Center the text */
  margin-top: 5px;  /* Move the text up slightly */
  font-size: 2em;   /* Adjust font size if needed */
  color: #ffffff;     /* Ensure the text color fits your design */
  padding-bottom: 10px; 
}

/* Drawer Container Logic */
.drawer-container {
  position: relative;
}

.drawer-container.open .mobile-event-drawer {
  transform: translateX(0);
}

/* Ensure no horizontal scrolling on mobile devices */
@media (max-width: 600px) {
  body, html {
    overflow-x: hidden;
    touch-action: pan-y; /* Lock horizontal movement, only allow vertical */
  }

  .App {
    width: 100vw; /* Ensure the app takes full width on mobile */
    max-width: 100vw; /* Prevent elements from exceeding the viewport width */
  }

  .main-content {
    padding: 0; /* Remove padding on mobile to prevent overflow */
    width: 100vw; /* Ensure no horizontal overflow */
  }

  .mobile-header {
    width: 100vw;
    max-width: 100%; /* Prevent any element from exceeding the viewport width */
  }
}
