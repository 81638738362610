.message-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Dark background to overlay the site */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it overlays everything */
}

.message-box {
  background-color: #1B1D22; /* Background color */
  color: #FFFFFF;
  padding: 10px;
  border-radius: 10px;
  max-width: 1200px;
  text-align: center; /* Center the content */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Add subtle shadow for emphasis */
}

.message-header h2 {
  margin: 0;
  font-size: 28px;
  color: #FFD700; /* Gold color for the header */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); /* Text shadow for better visibility */
  padding-bottom: 10px;
  border-bottom: 2px solid #FFD700; /* Underline to separate header */
}

.message-box p {
  font-size: 16px;
  margin: 10px 0;
  line-height: 1.5; /* Improve readability */
}

.message-box ul {
  list-style-type: disc;
  margin: 10px 0;
  padding-left: 200px;
  text-align: left; /* Align text to the left */
}

.message-box li {
  margin-bottom: 2px;
}


/* Style for the "Sign up now" button */
button.signup-button {
  background-color: #FF4444;
  color: white;
  border: none;
  padding: 15px 30px; /* Larger button with more padding */
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  transition: background-color 0.3s ease; /* Smooth transition for hover */
}

/* Hover effect for the button */
button.signup-button:hover {
  background-color: #CC0000; /* Slightly darker red for hover */
}


/* Style for the image to ensure proper alignment and spacing */
.message-box img {
  margin-top: 00px !important;
  padding-top: -10px;
  width: 100%;
  max-width: 600px;
  border-radius: 5px; /* Rounded edges for aesthetic */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for focus */
}
