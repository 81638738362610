/* MobileDatePickerButton.css */
.mobile-date-picker-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  padding: 0;
  margin: 0;
  background-color: #3D3C3C;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.calendar-icon {
  width: 100%;
  height: 100%;
}

.date-picker-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #464545;
  border-radius: 20px;
  width: 65px;
  height: 65px;
}

/* Hover effect for the button */
.mobile-date-picker-button:hover {
  background-color: #464545;
}

/* Style for the date picker wrapper */
.date-picker-wrapper {
  position: fixed; /* Ensure it's fixed to the viewport */
  bottom: 0; /* Stick to the bottom of the screen */
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  background-color: white;
  border-radius: 20px 20px 0 0; /* Rounded top corners */
  transition: transform 0.3s ease;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); /* Add a slight shadow for emphasis */
  transform: translateY(100%); /* Start hidden below the screen */
}

.date-picker-inner-wrapper {
  padding: 20px;
}

/* Slide-up animation when picker is opened */
.slide-up {
  transform: translateY(0%); /* Slide up to the visible area */
}

/* Slide-down animation when picker is closed */
.slide-down {
  transform: translateY(100%); /* Slide down to hide below the screen */
}
